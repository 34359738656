import http from "../http-common";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import store from "../store";

class ImmunkarteService {
    getAll() {
        let url = "/immunkartes"
        if (!AuthService.hasRole(store.state.auth.user, 'ImmunkartePrinter')) {
            url += "/mine"
        }
        return http.get(url, { headers: authHeader() });
    }

    getAllUnprinted() {
        let url = "/immunkartes"
        if (!AuthService.hasRole(store.state.auth.user, 'ImmunkartePrinter')) {
            url += "/mine"
        }
        return http.get(url, {
            params: {
                unprinted: 1
            },
            headers: authHeader()
        });
    }

    printSelected(immunkartesIds) {
        return http.post('/immunkartes/print',
            { immunkarteIds: immunkartesIds },
            {
                headers: authHeader(),
                responseType: 'blob',
            }
        )
    }

    markUnprinted(id) {
        return http.get(`/immunkartes/markunprinted/${id}`, { headers: authHeader() });
    }

    get(id) {
        return http.get(`/immunkartes/${id}`, { headers: authHeader() });
    }

    create(data) {
        return http.post("/immunkartes", data, { headers: authHeader() });
    }

    delete(id) {
        return http.delete("/immunkartes/" + id, { headers: authHeader() });
    }

    findByPatientData(forename, surname, unprinted) {
        let url = "/immunkartes"
        if (!AuthService.hasRole(store.state.auth.user, 'ImmunkartePrinter')) {
            url += "/mine"
        }
        return http.get(url, {
            params: {
                unprinted: unprinted,
                forename: forename,
                surname: surname
            },
            headers: authHeader()
        });
    }
}

export default new ImmunkarteService();